import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import '../Styles/App.css';
import{ Navbar, Nav} from 'react-bootstrap';
import {FaInstagram} from 'react-icons/fa';
import {FiFacebook} from 'react-icons/fi';


const Navigate = () => {
    return(
        <>
        <Navbar collapseOnSelect expand={false} bg="light" variant="light" className="navbar" fixed="top">
            <Navbar.Brand href="/">
                <img 
                src="https://res.cloudinary.com/legz444/image/upload/v1624299955/skin_collective/primary_logo_sgdmn6.png" 
                width="80"
                height="80"
                className="d-inline-block align-top"
                alt="Skin Collective Logo"
                />
            </Navbar.Brand>
            
            <Navbar.Text className="nav d-flex smicon">
                <Nav.Link className="d-inline-block p-3" href="https://www.facebook.com/skincollectiveboulder">
                    <FiFacebook/>
                </Nav.Link>
                <Nav.Link className="d-inline-block p-3" href="https://www.instagram.com/boulderskincollective/">
                    <FaInstagram/>
                </Nav.Link>
            </Navbar.Text>
            <Navbar.Toggle className="nav_control_btn" aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                    <Nav.Link className="navLink" href="/about" center>About</Nav.Link>
                    <Nav.Link className="navLink" href="/serviceMenu" center>Service Menu</Nav.Link>
                    <Nav.Link className="navLink" href="#contact" center>Contact</Nav.Link>
                    <Nav.Link className="navLink" href="https://www.myaestheticspro.com/clientportal/dsp_loginportal.cfm?6C7A1A2C8B9ED7DBDF0D8A88769FF081" center>Log-In Patient Portal</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
        </>
    )
}
export default Navigate;