import React from 'react';
import '../Styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tegan from "../Components/Tegan";
import Navigate from "../Components/Navigation";
import Contact from "../Components/Contact";

const Bio = () => {
    return(
        <>
        <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
            integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
            crossorigin="anonymous"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300&family=Rajdhani:wght@300&display=swap" rel="stylesheet"/>
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link href="https://fonts.googleapis.com/css2?family=Fjord+One&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.cdnfonts.com/css/aubrey" rel="stylesheet"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon.ico"></link>
        <nav className="navigate">
            <Navigate/>
        </nav>
        <body className="about_page">
            <Tegan/>
            <article id="contact" className="Contact">
            <Contact/>
            </article>
        </body>
            
        </>
    )

}
export default Bio;