import React from 'react';
import{Carousel} from 'react-bootstrap';

const Photos = () => {
 return(
     <>
        <Carousel fade className="carousel mx-auto pt-5">
            <Carousel.Item>
                <img
                className="ss_img d-block w-50 mx-auto"
                src="https://res.cloudinary.com/legz444/image/upload/v1671682561/skin_collective/slidehsow2_kgm9lh.jpg"
                alt="Woman's face, close up"
                />
                <Carousel.Caption>
                {/* <h5>Skin Collective is</h5> */}
                <h2>PERSONALIZED</h2>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="ss_img d-block w-50 mx-auto"
                src="https://res.cloudinary.com/legz444/image/upload/v1671682558/skin_collective/slideshow4_copy_zjdgpe.jpg"
                alt="facial"
                ></img>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="ss_img d-block w-50 mx-auto"
                src="https://res.cloudinary.com/legz444/image/upload/v1671682562/skin_collective/slideshow1_swcoam.jpg"
                alt="Facial Treatment"
                />

                <Carousel.Caption>
                {/* <h5>Skin Collective is</h5> */}
                <h2>PROFESSIONAL</h2>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="ss_img d-block w-50 mx-auto"
                src="https://res.cloudinary.com/legz444/image/upload/v1671682560/skin_collective/slideshow3_ikcz0f.jpg"
                alt="facial"
                ></img>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="ss_img d-block w-50 mx-auto"
                src="https://res.cloudinary.com/legz444/image/upload/v1671683279/skin_collective/slideshow6_inynuj.jpg"
                alt="Dropper of Facial Serum"
                />

                <Carousel.Caption>
                {/* <h5>Skin Collective is</h5> */}
                <h2>AUTHENTIC</h2>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="ss_img d-block w-50 mx-auto"
                src="https://res.cloudinary.com/legz444/image/upload/v1671682557/skin_collective/slideshow5_idpwzq.jpg"
                alt="facial"
                ></img>
            </Carousel.Item>
            </Carousel>
     </>
 )
}

export default Photos;