import React from 'react';
import '../Styles/App.css';
import {Card, Table} from 'react-bootstrap';

const Tegan = () => {
    return(
        <>
            <h1 className="page_title">Meet your Team</h1>
            <div className="employees">
                <Card className="mx-auto tegan_card" >
                    <Card.Img variant="top" src="https://res.cloudinary.com/legz444/image/upload/v1665763817/skin_collective/0A17D4D1-DD8E-48C4-8BC9-51C8248C1E95_hbrqyy.jpg" className="tegan_image_card w-50 mx-auto"></Card.Img>
                    <Card.Body>
                    <Card.Title className="tegan_title">Tegan Ryan</Card.Title>
                    <Card.Subtitle className="tegan_title p-2">Founder and Lead Aesthetician</Card.Subtitle>
                    <Card.Text className="tegan_text p-5">Tegan has been a licensed aesthetician for 6 years. For 5 of those years, she was the lead aesthetician at a top dermatologist in Boulder and has now started her dream company: <strong>Skin Collective</strong>. Tegan works with dozens of skincare lines and countless technologies, providing her clients with individualized and high quality care.<br></br><br></br> 
                    <span>Her passion is to create a space that utilizes the best of the best.</span>
                    <br></br><br></br>Each product line and piece of equipment has been carefully chosen to tackle tough skin concerns like <strong>pigmentation, acne, sensitivity,</strong> and of course, <strong>aging.</strong> Tegan loves to create a space that evokes the feelings of a day spa but where clients leave the treatment room with noticeable results and are inspired to continue their skincare at home.</Card.Text>
                    </Card.Body>
                </Card>
                <Table responsive className="about_reviews" colspan="3">
                    <tr>
                        <td className="about_quote" valign="top" colspan="1">
                            <p>"Tegan personalizes her treatments to each client and spends time on education. She does an amazing job guaging the expectations and goals of the client so that the experience can truly be individualized. Being the primary provider at Mandel Dermatology in Boulder, we as an office will be referring our patients to Tegan at the Skin Collective."<br></br><strong>-Arielle A.</strong></p>
                        </td>
                        <td className="about_quote" valign="top" colspan="1">
                            <p>"I feel so fortunate to have found Tegan - and my skin is too! I've been struggling with acne for several years, tried all the things, and feel like I'm really seeing results with Tegan's guidance. Her products are top notch and she always goes above and beyond, helping me with whatever my face needs at the time of our appointments."<br></br><strong>-Jessica L.</strong></p>
                        </td>
                        <td className="about_quote" valign="top" colspan="1">
                            <p>"When I first met Tegan, I had awfully dry, irritated and inflamed skin - and I had no idea how wrong my skincare regime was. She was able to transform my skin in my first visit and gave me game-changing advice about the correct products to use moving forward."<br></br><strong>-Liz C.</strong></p>
                        </td>
                    </tr>
                </Table>
                <Card className="mx-auto tegan_card" >
                    <Card.Img variant="top" src="https://res.cloudinary.com/legz444/image/upload/v1665763826/skin_collective/3B809B9F-94D2-432D-8CF3-175B6472103C_gorni1.jpg" className="tegan_image_card w-50 mx-auto"></Card.Img>
                    <Card.Body>
                    <Card.Title className="tegan_title">Mandy Brock</Card.Title>
                    <Card.Subtitle className="tegan_title p-2">Aesthetician</Card.Subtitle>
                    <Card.Text className="tegan_text p-5">Mandy is an excited addition to the Skin Collective team, where customized skincare according to each individual's needs is a priority. Mandy offers a <strong>unique natural health approach</strong> and results-driven mindfulness to our practice. Mandy understands the environmental toll skin takes on a daily basis and the immeasurable impact great skincare can have on a person's life. She is passionate about educating her clients to understand how their lifestyle and environment contribute to their specific skincare needs and how to choose and apply the correct products and modalities for consistent results and healthy glowing skin.</Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}
export default Tegan;